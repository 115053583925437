import { permissions } from '@mpk/shared/domain';

enum PlatformPackagingUnitsPermissionsEnum {
  None = 0,
  ManagePlatformPackagingUnits = 1,
}

export const PlatformPackagingUnitsPermissions = permissions(PlatformPackagingUnitsPermissionsEnum, {
  featureId: 'PlatformPackagingUnits',
});
